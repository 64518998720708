module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"fucking.digital","short_name":"fucking.digital","start_url":"/?utm_source=pwa&utm_medium=visit&utm_campaign=website2020","background_color":"#ffffff","orientation":"portrait","theme_color":"#ffffff","display":"standalone","icon":"src/images/app-icons/icon-32.png","icons":[{"src":"app-icons/icon-128.png","type":"image/png","sizes":"128x128"},{"src":"app-icons/icon-192.png","type":"image/png","sizes":"192x192"},{"src":"app-icons/icon-512.png","type":"image/png","sizes":"512x512"}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
